import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as Actions from '../containers/MindtrexLayout/redux/actions'
import { profile } from '../views/Auth/redux/actions'
import { getProfile } from './api'

const PrivateRoute = ({
  component: Component,
  render,
  path,
  name,
  ...props
}) => {
  const isLogin = JSON.parse(localStorage.getItem('mindtrex_auth'))
  const [isError, setError] = React.useState(false)
  const [isLoading, setLoading] = React.useState(true)
  const Login = React.lazy(() => import('../views/Auth/Login/Login'))

  useEffect(() => {
    // let timer1 = setTimeout(() => setLoading(false), 3000)
    // return () => {
    //   clearTimeout(timer1)
    // }
    try{
      getProfile().then(response => {
          return true
      }).catch((e) => {
          localStorage.removeItem('mindtrex_auth')
          localStorage.removeItem('profile')
          localStorage.removeItem('school_id')
          localStorage.removeItem('region_id')
          localStorage.removeItem('school_type')
          window.location.reload()

      })
    }catch(e){
        //Jika token invalid atau expired, redirect ke login
        localStorage.removeItem('mindtrex_auth')
        localStorage.removeItem('profile')
        localStorage.removeItem('school_id')
        localStorage.removeItem('region_id')
        localStorage.removeItem('school_type')
        window.location.reload()
    }

    let isAuth = props.signin.initial_auth
    if (isLogin && isAuth === false) {
      props.profile(true).then((result) => {
        setLoading(false)
      })
    }
  }, [])
  
  if (props.location.pathname === '/') {
    return <Redirect from="/:any" to="/dashboard" />
  }

  if (!isLogin) {
    return <Redirect from="/:any" to="/login" />
  }

  if (isError) {
    return (
      <div>
        error occured when fetching data
        <span>
          <button onClick={(_) => window.location.reload()}>try again ?</button>
        </span>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="animated fadeIn pt-1 text-center loading-component">
        <div className="sk-spinner sk-spinner-pulse"></div>
      </div>
    )
  }

  return (
    <Route
      path={path}
      name={name}
      render={render ? render : (props) => <Component {...props} />}
    />
  )
}

const mapStateToProps = (state) => ({
  ...state,
})
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...Actions, profile}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
