export const GET_GRADES = 'GET_GRADES'
export const GET_SUBJECT = 'GET_SUBJECT'
export const GET_TOPIC = 'GET_TOPIC'
export const GET_SUBTOPIC = 'GET_SUBTOPIC'

export const POST_GRADES = 'POST_GRADES'
export const POST_SUBJECT = 'POST_SUBJECT'
export const POST_TOPIC = 'POST_TOPIC'
export const POST_SUBTOPIC = 'POST_SUBTOPIC'

export const PUT_GRADES = 'PUT_GRADES'
export const PUT_SUBJECT = 'PUT_SUBJECT'
export const PUT_TOPICS = 'PUT_TOPICS'
export const PUT_SUB_TOPICS = 'PUT_SUB_TOPICS'