import * as types from './types'

const initialState = {
  languages: [],
  difficulties: [],
  questionTypes: [],
}

export const OtherLists = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LANGUAGES:
      return {
        ...state,
        languages: action.data,
        languages_total: action.total,
      }
    case types.GET_DIFFICULTIES:
      return {
        ...state,
        difficulties: action.data,
        difficulties_total: action.total,
      }
    case types.GET_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: action.data,
        questionTypes_total: action.total,
      }
    case types.GET_LEVEL_LIST:
      return {
        ...state,
        levels: action.data,
        levels_total: action.total,
      }
    case types.GET_GRADE_LIST:
      return {
        ...state,
        grades: action.data,
        grades_total: action.total,
      }
    default:
      return initialState
  }
}
