import * as types from './types'

const initialState = {
  lesson_list: [],
  question_story: {},
  subjects: [],
  grades: [],
  topics: [],
  subtopics: [],
  languages: [],
  LessonState: {
    description: '',
    free_content: false,
    grade: [],
    icon: '',
    level: [],
    name: '',
    subject: [],
    subtopic: [],
    topic: [],
    unit_path: [],
    unsigned_theory: [],
    unsigned_question: [],
    theories: [],
    questions: [],
    language: [],
    pivotTableValue: [],
    saveQuestion: false,
  },
}

export const LessonList = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LESSON_LIST:
      return {
        ...state,
        lesson_list: action.data,
        lesson_list_total: action.total,
      }
    case types.GET_QUESTION_STORY:
      return {
        ...state,
        question_story: action.data,
        question_story_total: action.total,
      }
    case types.GET_GRADES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_LANGUAGES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_SUBJECT:
      return {
        ...state,
        subjects: action.data,
        topics: [],
        subtopics: [],
      }
    case types.GET_TOPIC:
      return {
        ...state,
        topics: action.data,
        subtopics: [],
      }
    case types.GET_SUBTOPIC:
      return {
        ...state,
        subtopics: action.data,
      }
    case types.GET_UNSIGNED_THEORY:
      return {
        ...state,
        theory_unsigned_list: action.data,
        theory_unsigned_total: action.total,
      }
    case types.LESSON_SET_STATE:
      // console.log(action.param)
      return {
        ...state,
        LessonState: action.param,
      }
    default:
      return initialState
  }
}
