import * as types from './types'

const initialState = {
  grades: [],
  subjects: [],
  topics: [],
  subtopics: [],
}

export const TopicTrees = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GRADES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_SUBJECT:
      return {
        ...state,
        subjects: action.data,
        topics: [],
        subtopics: [],
      }
    case types.GET_TOPIC:
      return {
        ...state,
        topics: action.data,
        subtopics: [],
      }
    case types.GET_SUBTOPIC:
      return {
        ...state,
        subtopics: action.data,
      }
    case types.PUT_GRADES:
      return {
        ...state,
        grades: action.data,
        subjects:[]
      }
    default:
      return initialState
  }
}
