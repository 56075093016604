export const GET_QUESTIONS = '[QUESTION] GET_QUESTIONS'
export const GET_DIFFICULTIES = '[QUESTION] GET_DIFFICULTIES'
export const GET_LANGUAGES = '[QUESTION] GET_LANGUAGES'
export const GET_QUESTION_TYPES = '[QUESTION] GET_QUESTION_TYPES'
export const GET_GRADES = '[QUESTION] GET_GRADES'
export const GET_SUBJECT = '[QUESTION] GET_SUBJECT'
export const GET_TOPIC = '[QUESTION] GET_TOPIC'
export const GET_SUBTOPIC = '[QUESTION] GET_SUBTOPIC'
export const GET_QUESTION_STORY = '[QUESTION STORIES] GET_QUESTION_STORY'
export const GET_QUESTION_STORIES = '[QUESTION STORIES] GET_QUESTION_STORIES'
export const STORY_SET_STATE = 'STORY_SET_STATE'
