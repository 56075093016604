import * as types from './types'

const initialState = {
  CourseState: {
    background_image: '',
    country: '',
    description: '',
    grade: [],
    icon: '',
    lesson: [],
    level: [],
    name: '',
    path_mode: [],
    published: false,
    school_id: '',
    subject: [],
    unsigned_lesson: [],
    lesson: [],
    saveLesson: false,
    middlewareQuestion: false,
    prerequisiteList: [],
    language: [],
    unit_path: [],
    deleted_lesson_path: [],
    background_color: '#FFFFFF',
  },
}

export const Courses = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_QUESTION_STORIES:
      return {
        ...state,
        question_stories: action.data,
        question_stories_total: action.total,
      }
    case types.GET_QUESTION_STORY:
      return {
        ...state,
        question_story: action.data,
        question_story_total: action.total,
      }
    case types.GET_GRADES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_LANGUAGES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_SUBJECT:
      return {
        ...state,
        subjects: action.data,
        topics: [],
        subtopics: [],
      }
    case types.GET_TOPIC:
      return {
        ...state,
        topics: action.data,
        subtopics: [],
      }
    case types.GET_SUBTOPIC:
      return {
        ...state,
        subtopics: action.data,
      }
    case types.COURSE_SET_STATE:
      // console.log(action.param)
      return {
        ...state,
        CourseState: action.param,
      }
    default:
      return initialState
  }
}
