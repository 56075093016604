import axios from 'axios'
import Swal from 'sweetalert2'
import api from '../../../config/api'
import { getMyProfile } from '../../../helper/api'
import * as types from './types'

export function login(email, password) {
  const request = axios.post(`${api.url}/directory/auth/signin?debug=true`, {
    email: email,
    password: password,
  })

  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: types.LOGIN,
          data: response,
        })
        localStorage.setItem('mindtrex_auth', JSON.stringify(response.data))
        this.profile(response.data.access_token).then((res) => {
          localStorage.setItem('profile', JSON.stringify(res.response.data))
        })

        return { response, msg: 'Logged In!', status: 'ok' }
      })
      .catch((reason) => {
        // console.log('yeah')
        Swal.fire({
          title: 'Error',
          icon: 'error',
          text: 'Wrong Email / Password!',
        })
        return { reason, msg: 'Wrong Email / Password!', status: 'error' }
      })
}

export function profile(param = false) {
  const request = getMyProfile()
  return (dispatch) =>
    request.then((response) => {
      let header_available = []
      let route_collective = []
      let per_school_route = {}
      response.data.route_access.map((parentObj) => {
        // console.log(parentObj)
        parentObj.school_routes.map((childObj) => {
          // console.log(childObj)
          header_available.push({
            country_id: parentObj.country,
            school_id: childObj.school.id,
            type: childObj.school.type,
          })
          Object.assign(per_school_route, {
            [childObj.school.id]: childObj.routes,
          })
          route_collective.push(...childObj.routes)
        })
      })
      // let school_id = localStorage.getItem('school_id')

      dispatch({
        type: types.PROFIL,
        data: response,
        access_route: route_collective,
        accessable_header: header_available,
        initial_auth: param,
        per_school_route: per_school_route,
      })
      return { response, msg: 'Logged In!', status: 'ok' }
    })
}
