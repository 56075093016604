import * as types from './types'

const initialState = {
  question_sets: [],
  question_set: [],
  subjects: [],
  grades: [],
  difficulties: [],
  topics: [],
  subtopics: [],
  languages: [],
  questionTypes: [],
  packageList: [],
  QuestionState: {
    start_date: '',
    close_date: '',
    level: [],
    grade: [],
    questionType: [],
    subtopic: [],
    topic: [],
    assignTo: [],
    difficulty: [],
    questions: [],
    questionsBackup: [],
    unsigned_questions: [],
    tags: [],
    subject: [],
    questionStory: [],
    voucherTier: [],
    timeLimitType: [],
    language: [],
    instruction: '',
    type: [],
    enableStar: false,
    enableVoucher: false,
    typeScore: false,
    enableXP: false,
    isOveride: false,
    isShuffle: false,
    name: '',
    correct_score: '',
    incorrect_score: '',
    blank_score: '',
    overide_score: 0,
    saveQuestion: false,
    middlewareQuestion: false,
    skipQuestion: false,
    global_time_limit: 0,
    background_color: '#FFFFFF',
    background_image: '',
    icon: '',
    score: [
      {
        score: 'A',
        higher_value: 100,
        lower_value: 81,
      },
      {
        score: 'B',
        higher_value: 80,
        lower_value: 66,
      },
      {
        score: 'C',
        higher_value: 65,
        lower_value: 51,
      },
      {
        score: 'D',
        higher_value: 50,
        lower_value: 27,
      },
      {
        score: 'E',
        higher_value: 26,
        lower_value: 0,
      },
    ],
  },
}

export const QuestionChallange = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_QUESTION_SETS:
      return {
        ...state,
        question_sets: action.data,
        question_sets_total: action.total,
      }
    case types.GET_QUESTION_SET:
      return {
        ...state,
        question_set: action.data,
      }
    case types.GET_QUESTIONS_STORY:
      return {
        ...state,
        question_story: action.data,
      }
    case types.GET_GRADES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_SUBJECT:
      return {
        ...state,
        subjects: action.data,
        topics: [],
        subtopics: [],
      }
    case types.GET_TOPIC:
      return {
        ...state,
        topics: action.data,
        subtopics: [],
      }
    case types.GET_SUBTOPIC:
      return {
        ...state,
        subtopics: action.data,
      }
    case types.GET_LANGUAGES:
      return {
        ...state,
        languages: action.data,
      }
    case types.GET_DIFFICULTIES:
      return {
        ...state,
        difficulties: action.data,
      }
    case types.GET_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: action.data,
      }
    case types.GET_VOUCHERS_LIST:
      return {
        ...state,
        voucher: action.data,
        voucher_total: action.total,
      }
    case types.GET_PACKAGE_LIST:
      return {
        ...state,
        packageList: action.data
      }
    case types.QSET_STATE2:
      return {
        ...state,
        QuestionState: action.param,
      }
    default:
      return initialState
  }
}
