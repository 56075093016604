import * as types from './types'

const initialState = {
  question_stories: [],
  question_story: {},
  subjects: [],
  grades: [],
  topics: [],
  subtopics: [],
  languages: [],
  PackageState: {
    background_image: '',
    course: [],
    course_selected: [],
    default_to_school: false,
    description: '',
    icon: '',
    name: '',
    price: [],
    published: false,
    saveQuestion: false,
    middlewareQuestion: false,
    activeTab: 0,
    type: [],
    topics: {
      name: "",
      children: [],
    },
  },
}

export const Packages = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PACKAGE_LIST:
      return {
        ...state,
        packages_list: action.data,
        packages_list_total: action.total,
      }
    // case types.GET_QUESTION_STORY:
    //   return {
    //     ...state,
    //     question_story: action.data,
    //     question_story_total: action.total
    //   }
    case types.GET_GRADES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_LANGUAGES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_SUBJECT:
      return {
        ...state,
        subjects: action.data,
        topics: [],
        subtopics: [],
      }
    case types.GET_TOPIC:
      return {
        ...state,
        topics: action.data,
        subtopics: [],
      }
    case types.GET_SUBTOPIC:
      return {
        ...state,
        subtopics: action.data,
      }
    case types.PACKAGE_SET_STATE:
      // console.log(action.param)
      return {
        ...state,
        PackageState: action.param,
      }
    default:
      return initialState
  }
}
