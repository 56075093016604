import * as types from './types'

const initialState = {
  theories: [],
  theory: [],
  subjects: [],
  grades: [],
  topics: [],
  subtopics: [],
  languages: [],
  theoryData: {
    isSet: false,
    grade: [],
    language: [],
    level: [],
    subject: [],
    subtopic: [],
    topic: [],
    type: [],
  },
}

export const Theories = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_THEORIES:
      return {
        ...state,
        theories: action.data,
        theories_total: action.total,
      }
    case types.GET_THEORY:
      return {
        ...state,
        theory: action.data,
        theories_detail_total: action.total,
      }
    case types.GET_GRADES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_LANGUAGES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_SUBJECT:
      return {
        ...state,
        subjects: action.data,
        topics: [],
        subtopics: [],
      }
    case types.GET_TOPIC:
      return {
        ...state,
        topics: action.data,
        subtopics: [],
      }
    case types.GET_SUBTOPIC:
      return {
        ...state,
        subtopics: action.data,
      }
    case types.SET_METADATATHEORY:
      return {
        ...state,
        theoryData: action.param,
      }
    default:
      return initialState
  }
}
