export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      // icon: 'icon-speedometer',
      // badge: {
      //   variant: 'info',
      //   text: 'NEW',
      // },
      level: 1,
      class: 'font-weight-bold',
      routeName: 'dashboard',
    },
    {
      name: 'Content Libraries',
      url: '/libraries',
      level: 1,
      class: 'font-weight-bold',
      routeName: 'libraries',
      children: [
        {
          name: 'Topic Trees',
          url: '/libraries/topic_trees',
          level: 2,
          class: 'ml-2',
          routeName: 'libraries/topic_trees',
        },
        {
          name: 'Questions',
          // url : '/libraries/questions',
          level: 2,
          class: 'ml-2 font-weight-bold',
          routeName: 'libraries/questions',
          children: [
            {
              name: 'Create New Question',
              url: '/libraries/questions/create',
              level: 3,
              class: 'ml-4',
              routeName: 'libraries/questions/create',
            },
            // {
            //   name: 'Create Question (BETA)',
            //   url: '/libraries/questions/beta/create',
            //   level: 3,
            //   class: 'ml-4',
            //   routeName: 'libraries/questions/beta/create',
            // },
            {
              name: 'Question Bank',
              url: '/libraries/questions/bank',
              level: 3,
              class: 'ml-4',
              routeName: 'libraries/questions/bank',
            },
            {
              name: 'Question Story',
              url: '/libraries/questions/question_story',
              level: 3,
              class: 'ml-4',
              routeName: 'libraries/questions/question_story',
            },
            {
              name: 'Question Report',
              url: '/libraries/questions/report',
              level: 3,
              class: 'ml-4',
              routeName: 'libraries/questions/report',
            },
          ],
        },
        {
          name: 'Question Sets',
          // url : '/libraries/question_sets',
          level: 2,
          class: 'ml-2 font-weight-bold',
          routeName: 'libraries/question-sets',
          children: [
            {
              name: 'Create New Question Set',
              url: '/libraries/create-questionsets',
              level: 3,
              class: 'ml-4',
              routeName: 'libraries/question-sets/create-questionsets',
            },
            {
              name: 'Question Sets Bank',
              url: '/libraries/question_sets',
              level: 3,
              class: 'ml-4',
              routeName: 'libraries/question-set/bank',
            },
          ],
        },
        {
          name: 'Theory',
          // url : '/libraries/theory',
          level: 2,
          class: 'ml-2 font-weight-bold',
          routeName: 'libraries/theory',
          children: [
            {
              name: 'Create New Theory',
              url: '/libraries/create-theory',
              level: 3,
              class: 'ml-4',
              routeName: 'libraries/theory/create',
            },
            {
              name: 'Theory Collection',
              url: '/libraries/theory',
              level: 3,
              class: 'ml-4',
              routeName: 'libraries/theory/collection',
            },
          ],
        },
        {
          name: 'Passage',
          // url : '/libraries/theory',
          level: 2,
          class: 'ml-2 font-weight-bold',
          routeName: 'libraries/passage',
          children: [
            {
              name: 'Create New Passage',
              url: '/libraries/create-passage',
              level: 3,
              class: 'ml-4',
              routeName: 'libraries/passage/create',
            },
            {
              name: 'Passage Collection',
              url: '/libraries/passage',
              level: 3,
              class: 'ml-4',
              routeName: 'libraries/passage/collection',
            },
          ],
        },
      ],
    },
    {
      name: 'Lesson',
      url: '/lesson',
      class: 'font-weight-bold',
      routeName: 'lesson',
      children: [
        {
          name: 'Create New Lesson',
          url: '/lesson/create',
          class: 'ml-2',
          routeName: 'lesson/create',
        },
        {
          name: 'Lesson Collection',
          url: '/lesson/list',
          class: 'ml-2',
          routeName: 'lesson/list',
        },
      ],
    },
    {
      name: 'Course',
      url: '/course',
      class: 'font-weight-bold',
      routeName: 'course',
      children: [
        {
          name: 'Create New Course',
          url: '/course/create',
          class: 'ml-2',
          routeName: 'course/create',
        },
        {
          name: 'Course List',
          url: '/course/list',
          class: 'ml-2',
          routeName: 'course/list',
        },
      ],
    },
    {
      name: 'Packages',
      url: '/packages',
      class: 'font-weight-bold',
      routeName: 'packages',
      children: [
        {
          name: 'Create New Packages',
          url: '/packages/create',
          class: 'ml-2',
          routeName: 'packages/create',
        },
        {
          name: 'Packages List',
          url: '/packages/list',
          class: 'ml-2',
          routeName: 'packages/list',
        },
      ],
    },
    {
      name: 'Gamification',
      url: '/gamification',
      class: 'font-weight-bold',
      routeName: 'gamification',
      children: [
        {
          name: 'XP',
          url: '/gamification/xp',
          level: 2,
          class: 'ml-2',
          routeName: 'gamification',
        },
        {
          name: 'Stars',
          url: '/gamification/stars',
          level: 2,
          class: 'ml-2',
          routeName: 'gamification',
        },
        // {
        //   name: 'Voucher',
        //   url: '/gamification/voucher',
        //   level: 2,
        //   class: 'ml-2',
        //   routeName: 'gamification',
        // },
        {
          name: 'Level',
          url: '/gamification/level/list',
          level: 2,
          class: 'ml-2',
          routeName: 'gamification',
        },
      ],
    },
    {
      name: 'Master Data',
      url: '/master-data',
      class: 'font-weight-bold',
      routeName: 'master-data',
    },
    {
      name: 'Insight',
      url: '/insight',
      class: 'font-weight-bold',
      routeName: 'insight',
    },
    {
      name: 'Challenge',
      url: '/challange',
      class: 'font-weight-bold',
      routeName: 'lesson',
      children: [
        {
          name: 'Create New Challenge',
          url: '/challange/create',
          class: 'ml-2',
          routeName: 'lesson/create',
        },
        {
          name: 'Challenge List',
          url: '/challange/list',
          class: 'ml-2',
          routeName: 'lesson/list',
        },
        // {
        //   name: 'Leaderboard Challenge',
        //   url: '/challange/leaderboard',
        //   show: ['indonesia'],
        //   class: 'ml-2',
        //   routeName: 'lesson',
        // },
      ],
    },
    {
      name: 'Push Notification',
      url: '/message',
      class: 'font-weight-bold',
      routeName: 'lesson',
      children: [
        {
          name: 'Create New Push Notification',
          url: '/message/create',
          class: 'ml-2',
          routeName: 'lesson/create',
        },
        {
          name: 'Push Notification List',
          url: '/message/list',
          class: 'ml-2',
          routeName: 'lesson/list',
        },
      ],
    },
    // {
    //   name: 'User Management',
    //   url: '/user-managenet',
    //   class: 'font-weight-bold',
    //   routeName: 'user-managenet',
    // },
    {
      name: 'Log',
      url: '/log',
      class: 'font-weight-bold',
      routeName: 'log',
    },
  ],
}
