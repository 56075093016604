import { combineReducers } from 'redux'
import * as Header from './containers/MindtrexLayout/redux/reducers'
import * as Signin from './views/Auth/redux/reducers'
import * as ChallangeReducer from './views/Challange/redux/reducers'
import * as CourseReducer from './views/Courses/redux/reducers'
import * as GamificationReducer from './views/Gamification/redux/reducers'
import * as LessonReducer from './views/Lesson/redux/reducers'
import * as PassageReducer from './views/Libraries/Passage/redux/reducers'
import * as Questions from './views/Libraries/Questions/redux/reducers'
import * as QuestionSets from './views/Libraries/QuestionSets/redux/reducers'
import * as QuestionStories from './views/Libraries/QuestionStories/redux/reducers'
import * as Theories from './views/Libraries/Theory/redux/reducers'
import * as TopicTrees from './views/Libraries/TopicTrees/redux/reducers'
import * as OtherLists from './views/MasterData/redux/reducers'
import * as PackagesReducer from './views/Packages/redux/reducers'
const reducer = combineReducers({
  ...Signin,
  ...Header,
  ...TopicTrees,
  ...OtherLists,
  ...Questions,
  ...QuestionStories,
  ...Theories,
  ...QuestionSets,
  ...GamificationReducer,
  ...LessonReducer,
  ...PackagesReducer,
  ...CourseReducer,
  ...PassageReducer,
  ...ChallangeReducer
})

export default reducer
