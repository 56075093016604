import * as types from './types'

const initalState = {
  user: {},
  access_route: [],
  initial_auth: false,
}

export const signin = (state = initalState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        user: action.data,
      }
    case types.PROFIL:
      return {
        dataUser: action.data,
        access_route: action.access_route,
        initial_auth: false,
        accessable_header: action.accessable_header,
        per_school_route: action.per_school_route,
      }
    default:
      return state
  }
}
