// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers'
import loggerMiddleware from './middleware/logger'
import monitorReducerEnhancer from './enchancer/monitorReducer'
// import '@atlaskit/css-reset'

const middlewares = [process.env.NODE_ENV === "development" ? loggerMiddleware : undefined, thunkMiddleware]
const middlewareEnhancer = applyMiddleware(...middlewares.filter(middleware => middleware))
const composedEnhancers = compose(
    middlewareEnhancer,
    monitorReducerEnhancer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION__() : noop => noop
)

const store = createStore(rootReducer, undefined, composedEnhancers)

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
