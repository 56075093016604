import React, { Component } from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import './App.scss'
import PrivateRoute from './helper/PrivateRoute'
import PublicRoute from './helper/PublicRoute'

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const MindtrexLayout = React.lazy(() => import('./containers/MindtrexLayout'))
const Login = React.lazy(() => import('./views/Auth/Login/Login'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <PublicRoute path="/login" exact name="root" component={Login} />
            <PrivateRoute
              path="/"
              name="Dashboard"
              render={(props) => <MindtrexLayout {...props} />}
            />
            <Route
              exact
              name="404"
              render={(props) => <Redirect to="/dashboard" />}
            />
            {/* <Route
              path="/login"
              name="Login"
              render={(props) => (
                <MindtrexLayout isEmptyLayout={true} {...props} />
              )}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <MindtrexLayout {...props} />}
            /> */}
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App
