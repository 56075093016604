import * as types from './types'

const initialState = {
  passages: [],
}

export const Passage = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PASSAGES:
      return {
        ...state,
        passages: action.data,
        passages_total: action.total,
      }
    case types.GET_PASSAGE:
      return {
        ...state,
        passages: action.data,
      }
    default:
      return initialState
  }
}
