import * as types from './types'

const initialState = {
  questions: [],
  subjects: [],
  grades: [],
  difficulties: [],
  topics: [],
  subtopics: [],
  languages: [],
  questionTypes: [],
  metadataState: {
    isSet: false,
    difficulty: [],
    grade: [],
    language: [],
    level: [],
    subject: [],
    subtopic: [],
    topic: [],
    tags: [],
    type: [],
  },
}

export const Questions = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_QUESTIONS:
      return {
        ...state,
        questions: action.data,
        questions_total: action.total,
      }
    case types.GET_GRADES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_SUBJECT:
      return {
        ...state,
        subjects: action.data,
        topics: [],
        subtopics: [],
      }
    case types.GET_TOPIC:
      return {
        ...state,
        topics: action.data,
        subtopics: [],
      }
    case types.GET_SUBTOPIC:
      return {
        ...state,
        subtopics: action.data,
      }
    case types.GET_LANGUAGES:
      return {
        ...state,
        languages: action.data,
      }
    case types.GET_DIFFICULTIES:
      return {
        ...state,
        difficulties: action.data,
      }
    case types.GET_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: action.data,
      }
    case types.SET_METADATA_HISTORY:
      // console.log(action.param)
      return {
        ...state,
        metadataState: action.param,
      }
    default:
      return initialState
  }
}
