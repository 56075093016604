import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PublicRoute = ({ component: Component, render, ...res }) => {
  const isLogin = JSON.parse(localStorage.getItem('mindtrex_auth'))

  if (isLogin) {
    return <Redirect from="/:any" to="/dashboard" />
  }
  return (
    <Route
      {...res}
      render={render ? render : (props) => <Component {...props} />}
    />
  )
}

export default PublicRoute
