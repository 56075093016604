export const GET_QUESTIONS = '[QUESTION SET] GET_QUESTIONS'
export const GET_QUESTIONS_STORY = '[GET QUESTIONS STORY] GET_QUESTIONS_STORY'
export const GET_QUESTION_SETS = '[QUESTION SET] GET_QUESTION_SETS'
export const GET_QUESTION_SET = '[QUESTION SET] GET_QUESTION_SET'
export const GET_DIFFICULTIES = '[QUESTION SET] GET_DIFFICULTIES'
export const GET_LANGUAGES = '[QUESTION SET] GET_LANGUAGES'
export const GET_QUESTION_TYPES = '[QUESTION SET] GET_QUESTION_TYPES'
export const GET_GRADES = '[QUESTION SET] GET_GRADES'
export const GET_SUBJECT = '[QUESTION SET] GET_SUBJECT'
export const GET_TOPIC = '[QUESTION SET] GET_TOPIC'
export const GET_SUBTOPIC = '[QUESTION SET] GET_SUBTOPIC'
export const GET_VOUCHERS_LIST = 'GET_VOUCHERS_LIST'
export const QSET_STATE2 = 'GET QUESTION SET STATE'
export const GET_PACKAGE_LIST = 'GET PACKAGE QUESTION CHALLANGE'
