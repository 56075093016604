import * as types from "./types";

const initalState = {
  region: null,
  school: null,
  regions: [],
  schools: []
};

export const Header = (state = initalState, action) => {
  switch (action.type) {
    case types.SET_REGION:
      return {
        ...state,
        region: action.data
      };
    case types.SET_SCHOOL:
      return {
        ...state,
        school: action.data

      };
    case types.GET_COUNTRIES:
      return {
        ...state,
        regions: action.data
      };
    case types.GET_SCHOOLS:
      return {
        ...state,
        schools: action.data
      };
    default:
      return state;
  }
};
