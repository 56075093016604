import * as types from './types'

const initialState = {
  xp: [],
  stars: [],
  questionTypes: [],
}

export const GamificationList = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_XP:
      return {
        ...state,
        xp: action.data,
        xp_total: action.total,
      }
    case types.GET_STARS:
      return {
        ...state,
        stars: action.data,
        stars_total: action.total,
      }
    case types.GET_VOUCHERS:
      return {
        ...state,
        voucher: action.data,
        voucher_total: action.total,
      }
    case types.GET_DIFFICULTIES:
      return {
        ...state,
        difficulty: action.data,
        difficulty_total: action.total,
      }
    case types.GETXP_GRADES:
      return {
        ...state,
        grades: action.data,
        grades_total: action.total,
      }
    case types.GET_LEVELS:
      return {
        ...state,
        level_list: action.data,
        level_total: action.total,
      }
    default:
      return initialState
  }
}
