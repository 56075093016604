import * as types from './types'

const initialState = {
  questions: [],
  subjects: [],
  grades: [],
  difficulties: [],
  topics: [],
  subtopics: [],
  languages: [],
  questionTypes: [],
  StoryState: {
    explanation: '',
    grade: [],
    language: [],
    level: [],
    passage: '',
    questions: [],
    samePage: false,
    subject: [],
    subtopic: [],
    tags: [],
    title: '',
    topic: [],
    unsigned_question: [],
    saveQuestion: false,
    passage_id: '',
    passages: [],
    unsigned_passage: [],
  },
}

export const QuestionStories = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_QUESTION_STORIES:
      return {
        ...state,
        question_stories: action.data,
        question_stories_total: action.total,
      }
    case types.GET_QUESTION_STORY:
      return {
        ...state,
        question_story: action.data,
        question_story_total: action.total,
      }
    case types.GET_QUESTIONS:
      return {
        ...state,
        questions: action.data,
        questions_total: action.total,
      }
    case types.GET_GRADES:
      return {
        ...state,
        grades: action.data,
        subjects: [],
      }
    case types.GET_SUBJECT:
      return {
        ...state,
        subjects: action.data,
        topics: [],
        subtopics: [],
      }
    case types.GET_TOPIC:
      return {
        ...state,
        topics: action.data,
        subtopics: [],
      }
    case types.GET_SUBTOPIC:
      return {
        ...state,
        subtopics: action.data,
      }
    case types.GET_LANGUAGES:
      return {
        ...state,
        languages: action.data,
      }
    case types.GET_DIFFICULTIES:
      return {
        ...state,
        difficulties: action.data,
      }
    case types.GET_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: action.data,
      }
    case types.STORY_SET_STATE:
      // console.log(action.param)
      return {
        ...state,
        StoryState: action.param,
      }
    default:
      return initialState
  }
}
