import React from 'react'

const UnderConstruc = React.lazy(() =>
  import('./views/Dashboard/UnderConstruction')
)
const Dashboard = React.lazy(() => import('./views/Dashboard'))
const Insight = React.lazy(() => import('./views/Insight/Index'))
//Question Sets
const QuestionSets = React.lazy(() =>
  import('./views/Libraries/QuestionSets/QuestionSets')
)
const AddQuestion = React.lazy(() =>
  import('./views/Libraries/QuestionSets/Details/AddQuestion')
)
const QuestionSetsDetails = React.lazy(() =>
  import('./views/Libraries/QuestionSets/Details/QuestionSetsDetails')
)
const QuestionSetsUpdate = React.lazy(() =>
  import('./views/Libraries/QuestionSets/Update/QuestionSetsDetails')
)
const AddQuestionEdit = React.lazy(() =>
  import('./views/Libraries/QuestionSets/Update/AddQuestion')
)
//End Question Sets

//Question
const Questions = React.lazy(() =>
  import('./views/Libraries/Questions/Questions')
)
const QuestionDetails = React.lazy(() =>
  import('./views/Libraries/Questions/Details/QuestionDetails')
)
const QuestionDetailsBeta = React.lazy(() =>
  import('./views/Libraries/QuestionsBeta/Details/QuestionDetails')
)
const EditQuestionDetailsBeta = React.lazy(() =>
  import('./views/Libraries/QuestionsBeta/Update/QuestionDetails')
)
const QuestionPreview = React.lazy(() =>
  import('./views/Libraries/QuestionsBeta/Details/Preview')
)
const QuestionUpdate = React.lazy(() =>
  import('./views/Libraries/Questions/Update/QuestionDetails')
)
//End Question

//Question Story
const QuestionStory = React.lazy(() =>
  import('./views/Libraries/QuestionStories/QuestionStory')
)
const QuestionStoryDetails = React.lazy(() =>
  import('./views/Libraries/QuestionStories/Details/QuestionStoryDetails')
)
const QuestionStoryUpdate = React.lazy(() =>
  import('./views/Libraries/QuestionStories/Update/QuestionStoryDetails')
)
const QuestionStoryAddQuestion = React.lazy(() =>
  import('./views/Libraries/QuestionStories/Details/AddQuestion')
)
const QuestionStoryAddPassage = React.lazy(() =>
  import('./views/Libraries/QuestionStories/Details/AddPassage')
)
//End Question Story

//QuestionReport
const QuestionReport = React.lazy(() =>
  import('./views/Libraries/QuestionsReport/Questions')
)
const QuestionReportEdit = React.lazy(() =>
  import('./views/Libraries/QuestionsReport/Update/QuestionDetails')
)
const PreviewQuestion = React.lazy(() =>
  import('./views/Libraries/QuestionsReport/Update/Preview')
)

//Theory
const Theory = React.lazy(() => import('./views/Libraries/Theory/Theory'))
const TheoryDetails = React.lazy(() =>
  import('./views/Libraries/Theory/Details/TheoryDetails')
)
const TheoryUpdate = React.lazy(() =>
  import('./views/Libraries/Theory/Update/TheoryDetails')
)
//End Theory

//Passage
const Passage = React.lazy(() => import('./views/Libraries/Passage/Passage'))
const PassageDetails = React.lazy(() =>
  import('./views/Libraries/Passage/Details/PassageDetails')
)
const PassageUpdate = React.lazy(() =>
  import('./views/Libraries/Passage/Update/PassageDetails')
)
//End Theory

/**
 *Course
 */
const Course = React.lazy(() => import('./views/Courses/CourseList'))
const CourseDetails = React.lazy(() =>
  import('./views/Courses/Details/CourseDetail')
)
const AddCourseLesson = React.lazy(() =>
  import('./views/Courses/Details/AddLesson')
)
const CourseUpdate = React.lazy(() =>
  import('./views/Courses/Update/CourseDetail')
)
const EditCourseLesson = React.lazy(() =>
  import('./views/Courses/Update/AddLesson')
)
//End Course

/**
 *Lesson
 */
const Lesson = React.lazy(() => import('./views/Lesson/LessonList'))
const LessonDetails = React.lazy(() =>
  import('./views/Lesson/Details/LessonDetail')
)
const AddTheoryQuestionset = React.lazy(() =>
  import('./views/Lesson/Details/AddTheory')
)
const LessonUpdate = React.lazy(() =>
  import('./views/Lesson/Update/LessonDetail')
)
//End Lesson

const Login = React.lazy(() => import('./views/Auth/Login/Login'))
const TopicTrees = React.lazy(() =>
  import('./views/Libraries/TopicTrees/TopicTrees')
)

/**
 * Master data
 */
const OtherLists = React.lazy(() => import('./views/MasterData/index'))
const AddLanguage = React.lazy(() => import('./views/MasterData/Languages/add'))
const EditLanguage = React.lazy(() =>
  import('./views/MasterData/Languages/edit')
)
const AddDifficult = React.lazy(() =>
  import('./views/MasterData/Difficults/add')
)
const EditDifficult = React.lazy(() =>
  import('./views/MasterData/Difficults/edit')
)
const AddQuestionType = React.lazy(() =>
  import('./views/MasterData/QuestionType/add')
)
const EditQuestionType = React.lazy(() =>
  import('./views/MasterData/QuestionType/edit')
)
const AddLevel = React.lazy(() => import('./views/MasterData/Level/add'))
const EditLevel = React.lazy(() => import('./views/MasterData/Level/edit'))
const AddGrade = React.lazy(() => import('./views/MasterData/Grade/add'))
const EditGrade = React.lazy(() => import('./views/MasterData/Grade/edit'))
/**
 * End Master data
 */

/**
 * Gamification
 */
const Gamification = React.lazy(() => import('./views/Gamification'))
const GamificationStars = React.lazy(() => import('./views/Gamification/Stars'))
const GamificationLevel = React.lazy(() => import('./views/Gamification/Level'))
const GamificationCreateStar = React.lazy(() =>
  import('./views/Gamification/Stars/create')
)
const GamificationCreateLevel = React.lazy(() =>
  import('./views/Gamification/Level/create')
)
const GamificationEditLevel = React.lazy(() =>
  import('./views/Gamification/Level/edit')
)
/**
 * end Gamification
 */
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

/**
 * Packages
 */
const Packages = React.lazy(() => import('./views/Packages/CourseList'))
const AddPackages = React.lazy(() =>
  import('./views/Packages/Details/CourseDetail')
)
const AddLessonPackage = React.lazy(() =>
  import('./views/Packages/Details/AddLesson')
)
const EditPackage = React.lazy(() =>
  import('./views/Packages/Update/CourseDetail')
)
const EditLessonPackage = React.lazy(() =>
  import('./views/Packages/Update/AddLesson')
)

const Challange = React.lazy(() =>
  import('./views/Challange/Challange')
)
const LeaderboardChallange = React.lazy(() =>
  import('./views/Challange/Leaderboard')
)
/**
 * end Packages
 */

const routes = [
  { path: '/', exact: true, name: 'Home', routeName: 'home' },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    routeName: 'dashboard',
    middleware: ['authenticatedUser'],
  },
  // { path: '/libraries', name: 'Libraries', exact: true },

  //Questions
  {
    path: '/libraries/questions/beta/create',
    name: 'Create New Question (BETA)',
    component: QuestionDetailsBeta,
    routeName: 'libraries/questions/beta/create',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/ibraries/questions/beta/preview',
    name: 'Preview Question',
    component: QuestionPreview,
    routeName: 'libraries/questions/beta/preview',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/questions/create',
    name: 'Create New Question',
    component: QuestionDetailsBeta,
    routeName: 'libraries/questions/create',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/questions/bank',
    name: 'Question Bank',
    component: Questions,
    middleware: ['authenticatedUser'],
    routeName: 'libraries/questions/bank',
    exact: true,
  },
  {
    path: '/libraries/questions/bank/edit/:id',
    name: 'Edit Question',
    component: EditQuestionDetailsBeta,
    routeName: 'libraries/questions/bank/edit',
    middleware: ['authenticatedUser'],
  },
  // { path: '/libraries/questions', name: 'Questions', exact: true},
  //End Questions

  //Question Sets
  {
    path: '/libraries/question_sets',
    name: 'Question Sets',
    exact: true,
    component: QuestionSets,
    routeName: 'libraries/question-set/bank',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/create-questionsets/:id',
    name: 'Add Question',
    component: AddQuestion,
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/create-questionsets',
    name: 'Create Question Sets',
    component: QuestionSetsDetails,
    routeName: 'libraries/question-sets/create-questionsets',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/question_sets/edit/:id/edit-question',
    name: 'Edit Question',
    component: AddQuestionEdit,
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/question_sets/edit/:id',
    name: 'Edit Question Sets',
    component: QuestionSetsUpdate,
    routeName: 'libraries/question-sets/edit-questionsets',
    middleware: ['authenticatedUser'],
  },

  //End Question Sets

  // Question Story
  {
    path: '/libraries/questions/question_story',
    name: 'Question Story',
    exact: true,
    component: QuestionStory,
    routeName: 'libraries/questions/question_story',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/questions/question_story/create',
    name: 'Create Question Story',
    component: QuestionStoryDetails,
    routeName: 'libraries/questions/question_story/create',
    middleware: ['authenticatedUser'],
  },
  // End Question Story

  //Question Report
  {
    path: '/libraries/questions/report/:id',
    name: 'Edit Question',
    component: QuestionReportEdit,
    routeName: 'libraries/questions/report/edit',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/questions/report',
    name: 'Report Question',
    component: QuestionReport,
    routeName: 'libraries/questions/report',
    middleware: ['authenticatedUser'],
  },
  // {
  //   path: '/preview',
  //   name: 'Preview Question',
  //   component: PreviewQuestion,
  //   routeName: 'preview/question',
  //   middleware: ['authenticatedUser'],
  // },
  //End Question Report

  // Theory
  {
    path: '/libraries/theory',
    name: 'Theory',
    exact: true,
    component: Theory,
    routeName: 'libraries/theory',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/create-theory',
    name: 'Create Theory',
    component: TheoryDetails,
    routeName: 'libraries/theory/create',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/theory/edit/:id',
    name: 'Edit Theory',
    component: TheoryUpdate,
    routeName: 'libraries/theory/edit',
    middleware: ['authenticatedUser'],
  },
  // End Theory

  // Passage
  {
    path: '/libraries/passage',
    name: 'Passage',
    exact: true,
    component: Passage,
    routeName: 'libraries/passage',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/create-passage',
    name: 'Create Passage',
    component: PassageDetails,
    routeName: 'libraries/passage/create',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/libraries/passage/edit/:id',
    name: 'Edit Passage',
    component: PassageUpdate,
    routeName: 'libraries/passage/edit',
    middleware: ['authenticatedUser'],
  },
  // End Passage

  // Course
  {
    path: '/course/list',
    name: 'Course',
    exact: true,
    component: Course,
    routeName: 'course/list',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/course/create/add-course-lesson',
    name: 'Add Course Lesson',
    component: AddCourseLesson,
    routeName: 'course/create/add-course-lesson',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/course/create',
    name: 'Create Course',
    component: CourseDetails,
    routeName: 'course/create',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/course/list/edit/:id/edit-course-lesson',
    name: 'Edit Course Lesson',
    component: EditCourseLesson,
    routeName: 'course/create/edit-course-lesson',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/course/list/edit/:id',
    name: 'Edit Course',
    component: CourseUpdate,
    routeName: 'course/edit',
    middleware: ['authenticatedUser'],
  },
  // End Course

  // Packages
  {
    path: '/packages/list',
    name: 'Packages List',
    exact: true,
    component: Packages,
    routeName: 'packages/list',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/packages/create/:id',
    name: 'Add Packages Lesson',
    component: AddLessonPackage,
    routeName: 'packages/create/lesson',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/packages/create',
    name: 'Create Packages',
    component: AddPackages,
    routeName: 'packages/create',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/packages/list/edit/:id/edit-lesson',
    name: 'Edit Lesson',
    component: EditLessonPackage,
    routeName: 'packages/edit/lesson',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/packages/list/edit/:id',
    name: 'Edit Packages',
    component: EditPackage,
    routeName: 'packages/edit',
    middleware: ['authenticatedUser'],
  },
  // End Packages

  // Lesson
  {
    path: '/lesson/list',
    name: 'Lesson List',
    exact: true,
    component: Lesson,
    routeName: 'lesson/list',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/lesson/create/:id',
    name: 'Add Theory & Question-set',
    component: AddTheoryQuestionset,
    routeName: 'lesson/create/theory-questionset',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/lesson/create',
    name: 'Create Lesson',
    component: LessonDetails,
    routeName: 'lesson/create',
    middleware: ['authenticatedUser'],
  },

  {
    path: '/lesson/list/edit/:id',
    name: 'Edit Lesson',
    component: LessonUpdate,
    routeName: 'lesson/edit',
    middleware: ['authenticatedUser'],
  },

  // End Lesson

  // Master Data
  {
    path: '/master-data',
    exact: true,
    component: OtherLists,
    routeName: 'master-data',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/master-data/:type',
    name: 'Master Data List',
    exact: true,
    component: OtherLists,
    routeName: 'master-data',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/master-data/:type/add-language',
    name: 'Create New Language',
    exact: true,
    component: AddLanguage,
    routeName: 'master-data/create-language',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/master-data/:type/edit-language/:id',
    name: 'Edit Language',
    exact: true,
    component: EditLanguage,
    routeName: 'master-data/edit-language',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/master-data/:type/add-difficulty',
    name: 'Create New Difficulty',
    exact: true,
    component: AddDifficult,
    routeName: 'master-data/create-difficulty',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/master-data/:type/edit-difficulty/:id',
    name: 'Edit Difficulty',
    exact: true,
    component: EditDifficult,
    routeName: 'master-data/edit-difficulty',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/master-data/:type/add-question-type',
    name: 'Create New Question Type',
    exact: true,
    component: AddQuestionType,
    routeName: 'master-data/add-question-type',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/master-data/:type/edit-question-type/:id',
    name: 'Edit Question Type',
    exact: true,
    component: EditQuestionType,
    routeName: 'master-data/edit-question-type',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/master-data/:type/add-level',
    name: 'Create New Level',
    exact: true,
    component: AddLevel,
    routeName: 'master-data/create-level',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/master-data/:type/edit-level/:id',
    name: 'Edit Level',
    exact: true,
    component: EditLevel,
    middleware: ['authenticatedUser'],
    routeName: 'master-data/edit-level',
  },
  {
    path: '/master-data/:type/add-grade',
    name: 'Create New Grade',
    exact: true,
    component: AddGrade,
    middleware: ['authenticatedUser'],
    routeName: 'master-data/create-grade',
  },
  {
    path: '/master-data/:type/edit-grade/:id',
    name: 'Edit Grade',
    exact: true,
    component: EditGrade,
    routeName: 'master-data/edit-grade',
    middleware: ['authenticatedUser'],
  },
  // End Master Data

  // User Management
  {
    path: '/user-managenet',
    name: 'User Management',
    exact: true,
    component: UnderConstruc,
    routeName: 'user-managenet',
    middleware: ['authenticatedUser'],
  },
  // End User Management

  // Log
  {
    path: '/log',
    name: 'Log',
    exact: true,
    component: UnderConstruc,
    routeName: 'log',
    middleware: ['authenticatedUser'],
  },
  // End Log

  // Gamifications
  {
    path: '/gamification',
    exact: true,
    component: Gamification,
    routeName: 'gamification',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/gamification/level/list/create/:id',
    name: 'Gamifications Level Add',
    component: GamificationCreateLevel,
    routeName: 'gamifications',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/gamification/level/list/edit/:id',
    name: 'Gamifications Level Edit',
    component: GamificationEditLevel,
    routeName: 'gamifications',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/gamification/level',
    name: 'Gamifications Level',
    component: GamificationLevel,
    routeName: 'gamifications',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/gamification/stars',
    name: 'Gamifications Stars',
    component: GamificationStars,
    routeName: 'gamifications',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/gamification/stars/create',
    name: 'Gamifications Stars Add',
    component: GamificationCreateStar,
    routeName: 'gamifications',
    middleware: ['authenticatedUser'],
  },
  {
    path: '/gamification/:type',
    name: 'Gamification',
    exact: true,
    component: Gamification,
    routeName: 'libraries/type',
    middleware: ['authenticatedUser'],
  },

  // End Gamifications
  { path: '/login', name: 'Login', component: Login, middleware: ['guest'] },
  {
    path: '/libraries/topic_trees',
    name: 'Topic Trees',
    component: TopicTrees,
    routeName: 'libraries/topic_trees',
    middleware: ['authenticatedUser'],
  },
  {
    name: 'Insight',
    path: '/insight',
    component: Insight,
    routeName: 'insight',
    middleware: ['authenticatedUser'],
  },

  // CHALLANHGE
  {
    name: 'Challenge List',
    path: '/challange/list',
    component: Challange,
    routeName: 'challange',
    middleware: ['authenticatedUser'],
  },
  {
    name: 'Leaderboard Challenge',
    path: '/challange/leaderboard',
    component: LeaderboardChallange,
    routeName: 'challange',
    middleware: ['authenticatedUser'],
  },
  // { path: '/otherlists', name: 'Other Lists', component: OtherLists, middleware: ['authenticatedUser'] }
]

export default routes
